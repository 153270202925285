import React from "react";



const DynamicTableCashout = (props) => {
  const dataImport = props.data;
  function renderSubHeaders() {
    let subHeaders = [];
    let subs = Object.values(dataImport);

    subs.map((x, i) => {
      if (subs[i] !== undefined) {
        Object.keys(subs[i]).map((y) => {
          subHeaders.push(<td>{y}</td>);
          return subHeaders;
        });
      }
      return subHeaders;
    });
    return subHeaders;
  }

  function renderResults() {
    let results = [];
    let res = Object.values(dataImport);

    res.map((x, i) => {
      if (res[i] !== undefined) {
        Object.values(res[i]).map((y) => {
          results.push(<td>{y}</td>);
          return results;
        });
      }
      return results;
    });

    return results;
  }

  
    function renderResultsMobile() {
    let results = [];
    let res = Object.values(dataImport);

    res.map((x, i) => {
      if (res[i] !== undefined) {
        Object.values(res[i]).map((y, index) => {
         
          results.push(
            <tr>
<td><b>{Object.keys(res[i])[index]}:</b></td>
<td>{y}</td>
            </tr>
          
          );
          return results;
        });
      }
      return results;
    });

    return results;
  }

  return (
    <React.Fragment>
      
      <table   className="display w-100 dataTable onBig"
                id="example5"
                role="grid"
                aria-describedby="example5_info">
        {/* <thead>
          <th className="row">{renderSubHeaders()}</th>
        </thead> */}
        <tbody>
        <tr style={{fontWeight: "bolder", borderBottom: "1px solid #6418C3"}}>{renderSubHeaders()}</tr>
          <tr>{renderResults()}</tr>
        </tbody>
      </table>

         <table   className="display w-100 dataTable onMobile"
                id="example5"
                role="grid"
                aria-describedby="example5_info">
        {/* <thead>
          <th className="row">{renderSubHeaders()}</th>
        </thead> */}
        <tbody>
      
          <tr>{renderResultsMobile()}</tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default DynamicTableCashout;
