import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';

import Avatar from 'react-avatar';

import { useEffect,Suspense,useState } from "react";
import axiosInstance from "../../../../../services/AxiosInstance";
import { da } from "date-fns/locale";
import DynamicTable from "../../DynamicTable";
import '../../../../../css/mod.css';
import PageTitle from "../../../../layouts/PageTitle";

const Currency = (props) => {
  const [show, setShow] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [showMeta, setShowMeta] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
  const [formState, setFormState] = useState({ cashout: 0, coupon: 0 });


   useEffect(() => {
    CallData(1,10);
    }, [props.match]); 
    const handleClose = async (e)  => {
      setShow(false);
  }

  const CallData =  async (page, limit)  => {
       
    
    
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
   
      axiosInstance.get("/api/admin/currency/get").then((rs) => {
        console.log(rs.data.data);
        setFormState({ ...formState, ['cashout']: parseFloat(rs.data.data.cashout.price), ['coupon']: parseFloat(rs.data.data.coupon.price), ['reduce']: parseFloat(rs.data.data.reduce.price) });
        setShow(false);
        
      });
}


const handleCoupon = async (e) => {
  try {
    setMsgT("Change Coupon Rate");
        setMsg("please wait...");
    setShow(true);
    e.preventDefault();
  
   let rs = await axiosInstance.post("/api/admin/currency/change", {
    "name": "NGN_EVOUCHER",
    "price": formState.coupon
    
    });
    setShow(false);
    CallData(1,10);

    // param is the argument you passed to the function
    // e is the event object that returned
   } catch (error) {
   
    if(typeof error.response !== "undefined"){
        
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
   }
};

const handleReduce = async (e) => {
  
  try {
    setMsgT("Change Cashout Rate");
        setMsg("please wait...");
    setShow(true);
    e.preventDefault();
  
   let rs = await axiosInstance.post("/api/admin/currency/change", {
    "name": "REDUCE_BTC_PRICE",
    "price": formState.reduce
    
    });
    setShow(false);
    CallData(1,10);

    // param is the argument you passed to the function
    // e is the event object that returned
   } catch (error) {
   
    if(typeof error.response !== "undefined"){
        
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
   }
};


const handleCashout = async (e) => {
  
  try {
    setMsgT("Change Cashout Rate");
        setMsg("please wait...");
    setShow(true);
    e.preventDefault();
  
   let rs = await axiosInstance.post("/api/admin/currency/change", {
    "name": "NGN",
    "price": formState.cashout
    
    });
    setShow(false);
    CallData(1,10);

    // param is the argument you passed to the function
    // e is the event object that returned
   } catch (error) {
   
    if(typeof error.response !== "undefined"){
        
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
   }
};
 const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormState({ ...formState, [name]: value });
  };
  const changeState = async e => {
    try {
     setMsgT(e.target.getAttribute("state"));
         setMsg("please wait...");
     setShow(true);
     e.preventDefault();
     let state = e.target.getAttribute("state");
     let id = e.target.getAttribute("id");
    let rs = await axiosInstance.post("/api/admin/transaction/change/state", {
         "id": id,
        "state": state
     
     });
     setShow(false);
     CallData(1,10);
 
     // param is the argument you passed to the function
     // e is the event object that returned
    } catch (error) {
    
     if(typeof error.response !== "undefined"){
         
         setMsg(error.response.data.errorMessage);
         setMsgT("Failed");
        
     }else{
         setMsg("Network error. Please try again later!");
         setMsgT("Failed");
     }
    }
     };

  const handleCloseMeta = async (e)  => {
    setShowMeta(false);
}


  

    const showMetaData = async (e) => {
        let metaData = "["+e.target.getAttribute("meta")+"]";
        let type = e.target.getAttribute("type");
        if(type == "CASHOUT"){
          let a = [];
          console.log(metaData);
          let bankInfo = JSON.parse(e.target.getAttribute("meta")).bank_info;
          console.log(Object.values(bankInfo));
          a.push(bankInfo);
          setTableData(a);
          setShowMeta(true);
        }else{
          setTableData(JSON.parse(metaData));
          setShowMeta(true);
        }
       
      
    }
   return (
    <Fragment>
    
    <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Update Currency</h4>
          </div>
          <div className="card-body">
            <form
              onSubmit={(e) => e.preventDefault()}
              id="step-form-horizontal"
              className="step-form-horizontal"
            >
              <section>
         <div className="row">
            <div className="col-sm-3 mb-2">
               <h4>Cashout Rate</h4>
            </div>
            <div className="col-6 col-sm-3 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.cashout}
                     type="number"
                     name="cashout"
                     id="cashout"
                  />
               </div>
            </div>
            <div className="col-6 col-sm-3 mb-2">
            <button
            onClick={handleCashout}
            
                                      type="submit"
                                      className="btn bg-primary text-white btn-block"
                                      
                                    >
                                     Update
                                    </button>
            </div>
         </div>
         <div className="row">
            <div className="col-sm-3 mb-2">
               <h4>Coupon rate</h4>
            </div>
            <div className="col-6 col-sm-3 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.coupon}
                     type="number"
                     name="coupon"
                     id="coupon"
                  />
               </div>
            </div>
            <div className="col-6 col-sm-3 mb-2">
            <button
            onClick={handleCoupon}
                                      type="submit"
                                      className="btn bg-primary text-white btn-block"
                                    >
                                     Update
                                    </button>
            </div>
         </div>
        

          <div className="row">
            <div className="col-sm-3 mb-2">
               <h4>Reduce BTC Price</h4>
            </div>
            <div className="col-6 col-sm-3 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.reduce}
                     type="number"
                     name="reduce"
                     id="reduce"
                  />
               </div>
            </div>
            <div className="col-6 col-sm-3 mb-2">
            <button
            onClick={handleReduce}
                                      type="submit"
                                      className="btn bg-primary text-white btn-block"
                                    >
                                     Update
                                    </button>
            </div>
         </div>
         
        
      </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
   );
};

export default Currency;
