import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import Avatar from 'react-avatar';

import { useEffect,Suspense,useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { da } from "date-fns/locale";

const EmailMarketing = () => {
  const [show, setShow] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
   const [userData, setUserData] = useState([]);
   const [userCount, setUserCount] = useState(0);
   const [page, setPage] = useState(1);
   const [dataCount, setDataCount] = useState(10);
   useEffect(() => {

    CallData(1,20);
    }, []); 
    const [formState, setFormState] = useState({ title: "", tmpID: "" });
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setFormState({ ...formState, [name]: value });
    };

    const CallData =  async (page, limit)  => {
      setMsgT("Loading");
      setMsg("Fetching data please wait...");
      setShow(true);
        axiosInstance.get("/api/admin/email/get/all/"+limit+"/"+page+"").then((rs) => {
          console.log(rs);
         setUserCount(rs.data.data.count)
          setUserData(rs.data.data.rows);
          setDataCount(rs.data.data.rows.length);
          setShow(false);
        });
     
  }
  
  const deleteEmail = async (e) => {
    try {
      setMsgT("Add Email Marketing ");
            setMsg("please wait...");
        setShow(true);
        e.preventDefault();
        console.log();
        let url = "/api/admin/email/delete";
        let sendPayLoad = {
         
          "id": e.target.id,
        
      
      }

      let rs = await axiosInstance.post(url, sendPayLoad);
      CallData(1,20);
      setShow(false);
    } catch (error) {
      if(typeof error.response !== "undefined"){
            
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
    }
  }

  const disable = async (e) => {
    try {
      setMsgT("Add Email Marketing ");
            setMsg("please wait...");
        setShow(true);
        e.preventDefault();
        console.log();
        let url = "/api/admin/email/disable";
        let sendPayLoad = {
         
          "id": e.target.id,
        
      
      }

      let rs = await axiosInstance.post(url, sendPayLoad);
      CallData(1,20);
      setShow(false);
    } catch (error) {
      if(typeof error.response !== "undefined"){
            
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
    }
  }


  const enable = async (e) => {
    try {
      setMsgT("Add Email Marketing ");
            setMsg("please wait...");
        setShow(true);
        e.preventDefault();
        console.log();
        let url = "/api/admin/email/enable";
        let sendPayLoad = {
         
          "id": e.target.id,
        
      
      }

      let rs = await axiosInstance.post(url, sendPayLoad);
      CallData(1,20);
      setShow(false);
    } catch (error) {
      if(typeof error.response !== "undefined"){
            
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
    }
  }
    const handleCashout = async (e) => {
      try {
        setMsgT("Add Email Marketing ");
            setMsg("please wait...");
        setShow(true);
        e.preventDefault();
        let url = "/api/admin/email/add";
        let sendPayLoad = {
          "start_date": new Date(),
          "end_date": new Date(),
          "email_template_id": formState.tmpID,
          "title": formState.title
      
      }
    
       
      
       let rs = await axiosInstance.post(url, sendPayLoad);
       CallData(1,20);
       setShow(false);
        // param is the argument you passed to the function
        // e is the event object that returned
       } catch (error) {
       console.log(error);
        if(typeof error.response !== "undefined"){
            
            setMsg(error.response.data.errorMessage);
            setMsgT("Failed");
           
        }else{
            setMsg("Network error. Please try again later!");
            setMsgT("Failed");
        }
       }
    };
    const handleClose = async (e)  => {
      setShow(false);
  }
    const next = async (e)  => {
      setMsgT("Loading");
      setMsg("Fetching data please wait...");
      setShow(true);
      e.preventDefault();
      setUserData([]);
      let nextPage  = page + 1;
      
      axiosInstance.get("/api/admin/email/get/all/10/"+nextPage+"").then((rs) => {
        setPage(nextPage);
        setUserCount(rs.data.data.count)
         setUserData(rs.data.data.rows);
         setDataCount(rs.data.data.rows.length);
         setShow(false);
       });
    }

    const pre = async (e)  => {
      e.preventDefault();
      setUserData([]);
      let nextPage  = page - 1;
      setMsgT("Loading");
      setMsg("Fetching data please wait...");
      setShow(true);
      axiosInstance.get("/api/admin/email/get/10/"+nextPage+"").then((rs) => {
        setPage(nextPage);
        setUserCount(rs.data.data.count)
         setUserData(rs.data.data.rows);
         setDataCount(rs.data.data.rows.length);
         setShow(false);
       });
    }
   return (
      <Fragment>
         <>
         {/* <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    
                </Suspense>
			</div> */}
               <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
         <div className="col-12">
            <div className="row">
            <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src="/images/symbols/users-relation-svgrepo-com.svg"  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Total <small>(marketing emails)</small></p>
                  <h4 className='mb-0'>{userCount}

</h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
            </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Marketing Emails</h4>
        <div className="col-md-6">
        <h4 className="card-title">Title</h4>
        <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.title}
                     type="text"
                     name="title"
                     id="title"
                     placeholder="Enter Title"
                  />
                  <br />
                  
                  <label className="card-title">Email Template ID</label>
        <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.tmpID}
                     type="text"
                     name="tmpID"
                     id="tmpID"
                     placeholder="Enter Email Template ID"
                  />
                  <br />
                    <button
            onClick={handleCashout}
            
                                      type="submit"
                                      className="btn bg-info text-white btn-block"
                                      
                                    >
                                     Add Email Marketing
                                    </button>
        </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper ">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">
                      Title
                    </th>
                    <th className="sorting" >
                      Email Template ID
                    </th>
                   
                    <th className="sorting" >
                     Active
                    </th>
                    <th className="sorting" >
                     Date
                    </th>
                    <th className="sorting" >
                     Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                
              
             
                 {
                  userData.map((i,e) => {

                    

                   return (
                     <tr className="even" role="row">
                     <td className="sorting_1"> <Avatar name={i.title} size="40" round={true} /> {i.title} </td>
                     <td>
  
                     {i.email_template_id}
                     </td>
                     
                     <td>
                     <span className={ i.running ? "badge badge-success" : "badge badge-danger"}>{ i.running ? "Active" : "Inactive"}</span>
                    </td>
                    <td>
                    <Moment fromNow ago>{i.createdAt}</Moment>
                    </td>
                     <td>
                    
                     
                     <Dropdown className='dropdown'>
                        <Dropdown.Toggle
                          variant='primary light'
                          className='i-false sharp'
                        >
                          <svg
                            width='18px'
                            height='18px'
                            viewBox='0 0 24 24'
                            version='1.1'
                          >
                            <g
                              stroke='none'
                              strokeWidth='1'
                              fill='none'
                              fillRule='evenodd'
                            >
                              <rect x='0' y='0' width={24} height={24} />
                              <circle fill='#000000' cx='5' cy='12' r='2' />
                              <circle fill='#000000' cx='12' cy='12' r='2' />
                              <circle fill='#000000' cx='19' cy='12' r='2' />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu'>
                          <Dropdown.Item
                           as={Link}
                            className='dropdown-item'
                            onClick={enable}
                            to="#"
                            id={i.id}
                          >
                            Enable
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                           tag={Link}
                            className='dropdown-item'
                            to='/hollox-user/portfolio/'
                          >
                            Ban
                          </Dropdown.Item> */}
                          <Dropdown.Item
                             as={Link}
                             className='dropdown-item'
                             onClick={disable}
                             to="#"
                             id={i.id}
                           >
                             Disable
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            className='dropdown-item'
                            to='/'
                          >
                            Trades
                          </Dropdown.Item> */}
                          <Dropdown.Item
                           
                            as={Link}
                            className='dropdown-item'
                            to="#"
                            id={i.id}
                            onClick={deleteEmail}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                     </td>
                   </tr>
                   )
                  })
                 }
                </tbody>
                
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {/* Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries */}
                </div>
                {/* onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} */}
                <div className="dataTables_paginate paging_simple_numbers" id="example5_paginate">
					
          {page > 1 ? (
            <Link className="paginate_button previous disabled" onClick={pre} >
						Previous
					</Link>
           ) : (<span></span>)}
					<span>
						{/* {paggination.map((number, i) => (
							<Link key={i} to="/table-datatable-basic" className={`paginate_button  ${ activePag.current === i ? "current" : ""} `}onClick={() => onClick(i)}>
								{number}
							</Link>
						))} */}
					</span>
               {/* onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)} */}
					
               {dataCount >= 10 ? (
            <Link className="paginate_button previous disabled" onClick={next} >
						Next
					</Link>
           ) : (<span></span>)}
          
         
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
         </>
      </Fragment>
   );
};

export default EmailMarketing;
