import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';

import Avatar from 'react-avatar';

import { useEffect,Suspense,useState } from "react";
import axiosInstanceBx from "../../../../../services/AxiosInstanceBx";
import { da } from "date-fns/locale";
import DynamicTable from "../../DynamicTable";
import '../../../../../css/mod.css';
import PageTitle from "../../../../layouts/PageTitle";

const SendCrypto = (props) => {
  const [show, setShow] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [showMeta, setShowMeta] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [type, setType] = useState("");
  const [msg, setMsg] = useState("Loading, please wait...");
  const [formState, setFormState] = useState({ key: "", to: "", amount: "", contract: "" });


   useEffect(() => {
    //console.log(props.match.params.type);
    setType(props.match.params.type);
    }, [props.match]); 
    const handleClose = async (e)  => {
      setShow(false);
  }




const handleCashout = async (e) => {
  try {
    setMsgT("Send "+type.toUpperCase().replace("-", " "));
        setMsg("please wait...");
    setShow(true);
    e.preventDefault();
    let url = "";
    let sendPayLoad = {};

    switch (type) {
      case "btc":
        url = "/bitcoin/send";
        sendPayLoad = {
          to: formState.to,
          key: formState.key,
          amount: formState.amount
        }
        break;
        case "btc-with-amount":
          url = "/bitcoin/send/with/amount";
          sendPayLoad = {
            to: formState.to,
            key: formState.key,
            amount: formState.amount
          }
          break;
      case "eth":
        sendPayLoad = {
          to: formState.to,
          key: formState.key,
          amount: formState.amount
        }
        url = "/ethereum/send/eth";
        break;

        case "bnb":
          url = "/binance/send/bnb";
          sendPayLoad = {
            to: formState.to,
            key: formState.key,
            amount: formState.amount
          }
          break;

          case "bsc-tokens":
            url = "/mcw/send/bsc/token";
            sendPayLoad = {
              address: formState.to,
              privateKey: formState.key,
              amount: formState.amount,
              contract: formState.contract
            }
            break;

            case "eth-tokens":
              url = "/mcw/send/eth/token";
              sendPayLoad = {
                address: formState.to,
                privateKey: formState.key,
                amount: formState.amount,
                contract: formState.contract
              }
              break;
        
      default:
        break;
    }
  
   let rs = await axiosInstanceBx.post(url, sendPayLoad);
   setMsg(rs.data);
   setMsgT("Success");
    
    // param is the argument you passed to the function
    // e is the event object that returned
   } catch (error) {
   console.log(error);
    if(typeof error.response !== "undefined"){
        
        setMsg(error.response.data.errorMessage);
        setMsgT("Failed");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Failed");
    }
   }
};

 const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormState({ ...formState, [name]: value });
  };
 

  const handleCloseMeta = async (e)  => {
    setShowMeta(false);
}


  

   return (
    <Fragment>
    
    <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Send {type.toUpperCase().replaceAll("-", " ")}</h4>
          </div>
          <div className="card-body">
            <form
              onSubmit={(e) => e.preventDefault()}
              id="step-form-horizontal"
              className="step-form-horizontal"
            >
              <section>
         <div className="row">
            <div className="col-sm-12 mb-2">
               <h4 className="text-info">Amount</h4>
            </div>
            <div className="col-12 col-sm-12 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.amount}
                     type="number"
                     name="amount"
                     id="amount"
                  />
               </div>
            </div>

            <div className="col-sm-12 mb-2">
               <h4 className="text-info">Recipient Wallet Address</h4>
            </div>
            <div className="col-12 col-sm-12 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.to}
                     type="text"
                     name="to"
                     id="to"
                  />
               </div>
            </div>

            <div className="col-sm-12 mb-2">
               <h4 className="text-info">Sender Private Key</h4>
            </div>
            <div className="col-12 col-sm-12 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.key}
                     type="text"
                     name="key"
                     id="key"
                  />
               </div>
            </div>

{type == "eth-tokens" || type == "bsc-tokens" ? (
  <>
    <div className="col-sm-12 mb-2">
               <h4 className="text-info">Contract Address</h4>
            </div>
            <div className="col-12 col-sm-12 mb-2">
               <div className="form-group">
                  <input
                     className="form-control"
                     onChange={handleChange}
                     value={formState.contract}
                     type="text"
                     name="contract"
                     id="contract"
                  />
               </div>
            </div>
  </>
) : null}
            <div className="col-6 col-sm-3 mb-2">
            <button
            onClick={handleCashout}
            
                                      type="submit"
                                      className="btn bg-primary text-white btn-block"
                                      
                                    >
                                     Send {type.toUpperCase().replaceAll("-", " ")}
                                    </button>
            </div>
         </div>
        
        
         
        
      </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
   );
};

export default SendCrypto;
