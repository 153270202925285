import React from 'react'

const Staff = () => {
  return (
    <>
<div className="col-12" style={{marginTop: "500px"}}>
            <div className="row">
              <h1>HELLO WORLD</h1>
</div>     
</div>
    </>
  )
}

export default Staff