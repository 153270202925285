import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';

import Avatar from 'react-avatar';

import { useEffect,Suspense,useState } from "react";
import axiosInstance from "../../../../../services/AxiosInstance";
import { da } from "date-fns/locale";
import DynamicTable from "../../DynamicTable";
import '../../../../../css/mod.css';
import { RangeDatePicker, DatePicker} from "@y0c/react-datepicker";
import BackButton from "../../../backButton/backButton";

const UserInternalTransactions = (props) => {
  
          const [show, setShow] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [showMeta, setShowMeta] = useState(false);
  const [isDateSearch, setIsDateSearch] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
   const [userData, setUserData] = useState([]);
   const [userCount, setUserCount] = useState(0);
   const [currency, setCurrency] = useState(0);
   const [btcPrice, setBtcPrice] = useState(0);
   const [dateSearch, setDateSearch] = useState({
    start: "",
    end: ""
   })
   const [page, setPage] = useState(1);
   const [dataCount, setDataCount] = useState(10);
   useEffect(() => {
    CallData(1,30);
    }, [props.match]); 
    const handleClose = async (e)  => {
      setShow(false);
  }
  const onDateChange = (date) => {
    // Day.js object
    console.log(date);

    
  }
  const onChange = title => (...args) => {
    console.log(args[1]);
   
    if(typeof args[0] != "undefined" && typeof args[1] != "undefined"){
      let startDate = new Date(args[0]).toISOString().slice(0, 19).replace('T', ' ');
      let endDate = new Date(args[1]).toISOString().slice(0, 19).replace('T', ' ');
      setDateSearch({
        start: startDate,
        end: endDate
      });
    }
    
    
   

  };
  const handleSearchByDate = async (e) => {
    let status = props.match.params.status;
    let userEmail = props.match.params.email;
    setPage(1);
    setIsDateSearch(true);
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
      axiosInstance.get("/api/admin/newuser/transaction/get/all/transactions/by/date/30/"+page+"/INTERNAL/"+status+"/"+dateSearch.start+"/"+dateSearch.end+"/"+userEmail).then((rs) => {
        console.log(rs);
       setUserCount(rs.data.data.tx.count)
        setUserData(rs.data.data.tx.rows);
        setDataCount(rs.data.data.tx.rows.length);
        setCurrency(parseFloat(rs.data.data.currency.price));
        setBtcPrice(parseFloat(rs.data.data.geck.current_price));
        setShow(false);
      });
  }

   const handleSearchByDateNext = async (page) => {
    let status = props.match.params.status;
   let userEmail = props.match.params.email;
    
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
      axiosInstance.get("/api/admin/newuser/transaction/get/all/transactions/by/date/30/"+page+"/INTERNAL/"+status+"/"+dateSearch.start+"/"+dateSearch.end+"/"+userEmail).then((rs) => {
        console.log(rs);
       setUserCount(rs.data.data.tx.count)
        setUserData(rs.data.data.tx.rows);
        setDataCount(rs.data.data.tx.rows.length);
        setCurrency(parseFloat(rs.data.data.currency.price));
        setBtcPrice(parseFloat(rs.data.data.geck.current_price));
        setShow(false);
      });
  }


  const CallData =  async (page, limit)  => {
       let userEmail = props.match.params.email;
    let status = props.match.params.status;
    
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
      axiosInstance.get("/api/admin/newuser/transaction/get/all/transactions/"+limit+"/"+page+"/INTERNAL/"+status+"/"+userEmail).then((rs) => {
        console.log(rs);
       setUserCount(rs.data.data.tx.count)
        setUserData(rs.data.data.tx.rows);
        setDataCount(rs.data.data.tx.rows.length);
        setCurrency(parseFloat(rs.data.data.currency.price));
        setBtcPrice(parseFloat(rs.data.data.geck.current_price));
        setShow(false);
      });
}
  const changeState = async e => {
    try {
     setMsgT(e.target.getAttribute("state"));
         setMsg("please wait...");
     setShow(true);
     e.preventDefault();
     let state = e.target.getAttribute("state");
     let id = e.target.getAttribute("id");
    let rs = await axiosInstance.post("/api/admin/transaction/change/state", {
         "id": id,
        "state": state
     
     });
     setShow(false);
     CallData(1,30);
 
     // param is the argument you passed to the function
     // e is the event object that returned
    } catch (error) {
    
     if(typeof error.response !== "undefined"){
         
         setMsg(error.response.data.errorMessage);
         setMsgT("Failed");
        
     }else{
         setMsg("Network error. Please try again later!");
         setMsgT("Failed");
     }
    }
     };

  const handleCloseMeta = async (e)  => {
    setShowMeta(false);
}
  
    const next = async (e)  => {
      setMsgT("Loading");
      setMsg("Fetching data please wait...");
      setShow(true);
      e.preventDefault();
      setUserData([]);
      var nextPage  = parseInt(page) + 1;
      setPage(nextPage);
      if(isDateSearch){
        handleSearchByDateNext(nextPage);
      }else{
CallData(nextPage,30);
      }
      
    }

    const pre = async (e)  => {
      e.preventDefault();
      setUserData([]);
      var nextPage  = parseInt(page) - 1;
       setPage(nextPage);
      if(isDateSearch){
        handleSearchByDateNext(nextPage);
      }else{
CallData(nextPage,30);
      }
    }

 
    const showMetaData = async (e) => {
        let metaData = "["+e.target.getAttribute("meta")+"]";
        let type = e.target.getAttribute("type");
        if(type == "CASHOUT"){
          let a = [];
          console.log(metaData);
          let bankInfo = JSON.parse(e.target.getAttribute("meta")).bank_info;
          console.log(Object.values(bankInfo));
          a.push(bankInfo);
          setTableData(a);
          setShowMeta(true);
        }else{
          setTableData(JSON.parse(metaData));
          setShowMeta(true);
        }
       
      
    }
   return (
      <Fragment>
         <>

         <Modal id="DTMH" className="display w-100 "  show={showMeta}  animation={false} onHide={handleCloseMeta}>
        <Modal.Header closeButton>
          <Modal.Title>Meta Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="display w-100  ">
            <DynamicTable data={tableData} />
        </Modal.Body>
      
      </Modal>

         {/* <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    
                </Suspense>
			</div> */}
               <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
         <div className="col-12">
     <BackButton />
            <div className="row">
            <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src="/images/symbols/users-relation-svgrepo-com.svg"  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Total <small>(Transactions)</small></p>
                  <h4 className='mb-0'>{userCount}

</h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
            </div>
       <div className="card">
      <div className="col-xl-12 col-lg-12">
          <div className="card">
           
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Search by date</p>
                    <RangeDatePicker
                      onChange={onChange("Range DatePicker")}
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                    />
                    <button style={{width: "399px"}}
             onClick={handleSearchByDate}
            
                                      type="submit"
                                      className="btn bg-danger text-white btn-block"
                                      
                                    >
                                     Search
                                    </button>
                  </div>
                </div>
              <div className="col-md-6 mb-3" style={{float: "right"}}>
                <br />
                <br />
          <div class="btn-group" role="group" aria-label="Basic example">
  <button type="button" class={props.match.params.status == "pending" ? "btn btn-success whiteFort" : "btn btn-default"}><Link  to={"/hollox-users-internal-transfer/transactions/"+props.match.params.email+"/pending/"+props.match.params.username}>Pending</Link> </button>
  <button type="button" class={props.match.params.status == "success" ? "btn btn-success whiteFort" : "btn btn-default"}><Link  to={"/hollox-users-internal-transfer/transactions/"+props.match.params.email+"/success/"+props.match.params.username}>Success</Link></button>
  <button type="button" class={props.match.params.status == "failed" ? "btn btn-success whiteFort" : "btn btn-default"}><Link  to={"/hollox-users-internal-transfer/transactions/"+props.match.params.email+"/failed/"+props.match.params.username}>Failed</Link></button>
</div>
        </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header">
          <h4 className="card-title">All Transactions</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper ">
              <table
         ggbsi       className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">
                      Type
                    </th>
                    <th className="sorting_asc" >
                      From
                    </th>
                    <th className="sorting">
                     To
                    </th>
                    <th className="sorting">
                      Amount
                    </th>
                    <th className="sorting">
                      Status
                    </th>
                    <th className="sorting">
                      Date
                    </th>
                   
                    <th className="sorting">
                     Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                
              
             
                 {
                  userData.map((i,e) => {

                    

                   return (
                     <tr className="even" role="row">
                     <td className="sorting_1"> <Avatar name={i.type} size="40" round={true} /> {i.type} </td>
                     <td className="sorting_1">  {i.email} <br /> <small style={{color: "red"}}>({JSON.parse(i.meta).from_username})</small></td>
                    <td className="sorting_1">  {i.to_email} <br /> <small style={{color: "red"}}>({JSON.parse(i.meta).to_username})</small></td>
                      {/* <td>
                    <NumericFormat  thousandSeparator=","   displayType="text"
 value=  {parseFloat(i.amount)} /> <b>{i.type== "CASHOUT" ? JSON.parse(i.meta).symbol  : JSON.parse(i.meta).symbol }</b> <br />


                    </td> */}

                       <td>
                    <NumericFormat  thousandSeparator=","   displayType="text"
 value=  {parseFloat(i.amount)} /> <b>{i.type== "CASHOUT" ? JSON.parse(i.meta).symbol  : JSON.parse(i.meta).symbol }</b> <br />

{i.type== "CASHOUT" || "DEPOSIT" ? (<NumericFormat  thousandSeparator=","  prefix="NGN"  displayType="text"
 value=  { JSON.parse(i.meta).symbol == "BTC" ? (parseFloat(i.amount) * btcPrice).toPrecision(2) * currency : parseFloat(i.amount) * currency} />  ): "" } <br />
 <b>Price: <NumericFormat  thousandSeparator=","  prefix="$"  displayType="text" value={btcPrice} /> </b>
< br />

 <span className="text-danger">{i.type== "CASHOUT" || "DEPOSIT" ? (<NumericFormat  thousandSeparator=","  prefix="$"  displayType="text"
 value=  { JSON.parse(i.meta).symbol == "BTC" ? ((parseFloat(i.amount) * btcPrice)).toFixed(4)  : parseFloat(i.amount) } />  ): "" } </span><br />
                    </td>
                     <td>
  
                     {i.status}
                     </td>
                     <td>
  
  
  <Moment fromNow ago>{i.createdAt}</Moment>
  </td>
                     <td>
                    
                     
                     <Dropdown className='dropdown'>
                        <Dropdown.Toggle
                          variant='primary light'
                          className='i-false sharp'
                        >
                          <svg
                            width='18px'
                            height='18px'
                            viewBox='0 0 24 24'
                            version='1.1'
                          >
                            <g
                              stroke='none'
                              strokeWidth='1'
                              fill='none'
                              fillRule='evenodd'
                            >
                              <rect x='0' y='0' width={24} height={24} />
                              <circle fill='#000000' cx='5' cy='12' r='2' />
                              <circle fill='#000000' cx='12' cy='12' r='2' />
                              <circle fill='#000000' cx='19' cy='12' r='2' />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu'>
                          <Dropdown.Item
                           onClick={showMetaData}
                            className='dropdown-item'
                            meta={i.meta}
                            type={i.type}
                          >
                            Meta
                          </Dropdown.Item>
                          <Dropdown.Item
                           onClick={changeState}
                            className='dropdown-item'
                            id={i.id}
                            state="SUCCESS"
                          >
                            Mark as successfully
                          </Dropdown.Item>
                          <Dropdown.Item
                           onClick={changeState}
                            className='dropdown-item'
                            id={i.id}
                            state="PENDING"
                          >
                            Mark as Pending
                          </Dropdown.Item>
                          
                        </Dropdown.Menu>
                      </Dropdown>
                     </td>
                   </tr>
                   )
                  })
                 }
                </tbody>
                
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {/* Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries */}
                </div>
                {/* onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} */}
                <div className="dataTables_paginate paging_simple_numbers" id="example5_paginate">
					
          {page > 1 ? (
            <Link className="paginate_button previous disabled" onClick={pre} >
						Previous
					</Link>
           ) : (<span></span>)}
					<span>
						{/* {paggination.map((number, i) => (
							<Link key={i} to="/table-datatable-basic" className={`paginate_button  ${ activePag.current === i ? "current" : ""} `}onClick={() => onClick(i)}>
								{number}
							</Link>
						))} */}
					</span>
               {/* onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)} */}
					
               {dataCount >= 30 ? (
            <Link className="paginate_button previous disabled" onClick={next} >
						Next
					</Link>
           ) : (<span></span>)}
          
         
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
         </>
      </Fragment>
   );
};

export default UserInternalTransactions;
